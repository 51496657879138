import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Kiube 0.4 coming soon...
        </p>
        <a style={{ color: '#EE7C6B' }} href="https://kiube.netlify.app" target="_blank">Kiube 0.3</a>
      </header>
    </div>
  );
}

export default App;
